import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-divine',
  templateUrl: './divine.component.html',
  styleUrls: ['./divine.component.scss']
})
export class DivineComponent implements OnInit {

  headerText = 'Free Shipping';
  text = ['Free Shipping', 'Hassle Free Returns'];

  currentBG = 'background-one';
  backgrounds = ['background-one', 'background-two'];

  constructor() { }

  ngOnInit(): void {
    this.changeText();
    this.changeBackground();
  }

  toggleSideNav() {
    const x = document.getElementById('sideNav');
    const y = document.getElementsByTagName('BODY')[0];

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  changeText(){
    for (let i = 0; i <= this.text.length - 1; i++) {
      setTimeout( ()=> {
        this.headerText = this.text[i + 0];
        if (i === this.text.length - 1) {
          this.changeText();
        }
      }, 4000*(i + 1)); //Change i+1 to i if you don't want delay for 1st image.
    }
  }

  changeBackground(){
    for (let i = 0; i <= this.text.length - 1; i++) {
      setTimeout( ()=> {
        this.currentBG = this.backgrounds[i + 0];
        if (i === this.backgrounds.length - 1) {
          this.changeBackground();
        }
      }, 6000*(i + 1)); //Change i+1 to i if you don't want delay for 1st image.
    }
  }
}
