<div class="navbar-desktop" id="navbar-desktop">
    <div class="container">
        <mat-toolbar>
            <mat-toolbar-row>
                <button>Home</button>
                <button>About</button>
                <button>Portfolio</button>
                <span class="spacer"></span>
                <a><img src="/../assets/img/photos/adventurebiker/logo.png"/></a>
                <span class="spacer"></span>
                <button>Work</button>
                <button>Blog</button>
                <button>Contact</button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <img src="/../assets/img/photos/adventurebiker/logo.png">
            </div>
            <div class="navbar-mobile-center">
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <img src="/../assets/img/photos/adventurebiker/logo.png">
            </div>
            <div class="side-nav-center">
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">Portfolio</button><hr>
                <button (click)="toggleSideNav()">Services</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
                <button (click)="toggleSideNav()">Login</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container">
        <div class="row">
            <div class="col-12">
            </div>
        </div>
    </div>
</div>