<div class="navbar-desktop">
    <div class="container">
        <img src="/assets/img/photos/onephotography/logo.png">
        <mat-toolbar>
            <mat-toolbar-row>
                <span class="spacer"></span>
                <button>Home</button>
                <button>About</button>
                <button>Gallery</button>
                <button>Blog</button>
                <button>Contact</button>
                <span class="spacer"></span>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container">
        <div class="row">
            <div class="navbar-mobile-left">
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
                <!-- <p>{{ appTitle }}</p> -->
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container">
        <div class="row">
            <div class="side-nav-left">
                <!-- <img src="/assets/img/photos/gameofthrones/got_logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
                <!-- <p>{{ appTitle }}</p> -->
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Gallery</button><hr>
                <button (click)="toggleSideNav()">Blog</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container">
        <div class="row">
            <div class="col-12">
            </div>
        </div>
    </div>
</div>
