<div class="navbar-info">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>{{ headerText }}</p>
            </div>
        </div>
    </div>
</div>

<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
        <mat-toolbar-row>
            <h1><i class="fas fa-spa"></i>Divine</h1>
            <span class="spacer"></span>
            <button routerLink="/divine" fragment="home">Home</button>
            <button routerLink="/divine" fragment="about">About</button>
            <button routerLink="/divine" fragment="shop">Hair Extensions</button>
            <button routerLink="/divine" fragment="shop">Lashes</button>
            <button routerLink="/divine" fragment="contact">Contact</button>
            <i class="fas fa-shopping-bag"></i>
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <h1><i class="fas fa-spa"></i>Divine</h1>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <h1><i class="fas fa-spa"></i>Divine</h1>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()" routerLink="/divine" fragment="home">Home</button><hr>
                <button (click)="toggleSideNav()" routerLink="/divine" fragment="about">About</button><hr>
                <button (click)="toggleSideNav()" routerLink="/divine" fragment="shop">Hair Extensions</button><hr>
                <button (click)="toggleSideNav()" routerLink="/divine" fragment="shop">Lashes</button><hr>
                <button (click)="toggleSideNav()" routerLink="/divine" fragment="contact">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-home" id="home">
    <img src="/assets/img/photos/divine/{{ currentBG }}.jpg" alt="background">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>
                    Beautiful.<br>
                    Strong.<br>
                    Confident.
                </p>
                <button>Shop Now</button>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-intro" id="about">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                <div class="card-about">
                    <h1>About our Services</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid natus consequuntur est amet, eaque dicta modi quia earum vitae mollitia sint iure minus, corporis beatae neque facilis totam asperiores deserunt?</p>
                    <button>Learn More</button>                    
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-shop-preview">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 content-left">
                <div class="card-left">
                    <h1>natural hair <br> extensions</h1>
                    <button class="primary">Shop Now</button>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 content-right">
                <div class="card-right">
                    <h1>glamorous <br> lashes</h1>
                    <button class="secondary">Shop Now</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-shop" id="shop">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <h1>Best Sellers</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid natus consequuntur est amet, eaque dicta modi quia earum vitae mollitia sint iure minus, corporis beatae neque facilis totam asperiores deserunt?</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <div class="card">
                    <h1>Best Seller</h1>
                    <img src="/assets/img/photos/divine/straight.jpg" alt="shopping-item">
                    <p>Straight Extension</p>
                    <h6>$150.00</h6>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <div class="card">
                    <h1>Best Seller</h1>
                    <img src="/assets/img/photos/divine/curly.jpg" alt="shopping-item">
                    <p>Curly Extension</p>
                    <h6>$150.00</h6>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <div class="card">
                    <h1>Best Seller</h1>
                    <img src="/assets/img/photos/divine/eyelashes-1.jpg" alt="shopping-item">
                    <p>Eye Lash Full</p>
                    <h6>$25.00</h6>
                </div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                <div class="card">
                    <h1>Best Seller</h1>
                    <img src="/assets/img/photos/divine/eyelashes-2.jpg" alt="shopping-item">
                    <p>Eye Lash Regular</p>
                    <h6>$20.00</h6>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <button>Shop All</button>                    
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>#divine</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-gallery">
    <div class="container-fluid padding">
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 padding">
                <img src="/assets/img/photos/divine/01.jpg" alt="beauty">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 padding">
                <img src="/assets/img/photos/divine/02.jpg" alt="beauty">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 padding">
                <img src="/assets/img/photos/divine/03.jpg" alt="beauty">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 padding">
                <img src="/assets/img/photos/divine/04.jpg" alt="beauty">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>As Seen On</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <img src="/assets/img/photos/divine/ulta-logo.png" alt="logos">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <img src="/assets/img/photos/divine/sephora-logo.png" alt="logos">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <img src="/assets/img/photos/divine/sallys-logo.png" alt="logos">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <img src="/assets/img/photos/divine/rite-aid-logo.png" alt="logos">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-footer" id="contact">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <h1>Be the first to know about special sales and new arrivals</h1>
                <input type="text" placeholder="Your Email ...">
                <button class="button">Subscribe</button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <button>Home</button>
                <button>Hair Extensions</button>
                <button>Lashes</button>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <button>About</button>
                <button>Contact</button>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fab fa-instagram"></i>
                <i class="fab fa-facebook"></i>
                <i class="fab fa-twitter"></i>
            </div>
        </div>
    </div>
</div>