<a (click)="toTopButton()" id="ToTopButton"><i class="fa fa-angle-up"></i></a>

<div class="jumbotron_footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>© {{ appTitle }}, {{ year }}. All Rights Reserved. | <a href="https://www.thegreenedgellc.com/terms"> Terms</a></p>
                <p>Design by: © <a href="https://www.thegreenedgellc.com"  style="color: #1ABC9C;">The Green Edge LLC</a></p>
            </div>
        </div>
    </div>
</div>