<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
        <mat-toolbar-row>
            <button>Home</button>
            <button>Shop</button>
            <button>Accessories</button>
            <span class="spacer"></span>
            <button>Sale</button>
            <button>Blog</button>
            <button>Contact</button>
            <i class="fas fa-shopping-bag"></i>
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Services</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Clothing Line <br> Website Inspiration</h1>
            </div>
            <div class="center">
                <a class="secondary">Shop Now</a>
                <a class="primary">Summer Sale!</a>
            </div>
            <div class="col-12">
                <div class="social-buttons">
                    <a><i class="fab fa-facebook"></i></a>
                    <a><i class="fab fa-instagram"></i></a>
                    <a><i class="fab fa-twitter"></i></a>
                    <a><i class="fab fa-snapchat"></i>  </a>              
                </div>
            </div>
        </div>
    </div>
</div>