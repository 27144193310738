<div class="navbar-desktop">
    <div class="container">
        <h1>Rj</h1>
        <button style="color: #9B59B6;">Home</button>
        <button>About</button>
        <button>Blog</button>
        <button>Gallery</button>
        <button>Contact</button>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container">
        <div class="row">
            <div class="navbar-mobile-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container">
        <div class="row">
            <div class="side-nav-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Blog</button><hr>
                <button (click)="toggleSideNav()">Gallery</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-md-none col-sm-none col-12">
            </div>
            <div class="col-lg-10 col-md-12 col-sm-12 col-12">
                <img class="img_50" src="/assets/img/photos/rachelsroom/attractive-beautiful-beauty-1027406.jpg">
                <img class="img_25" src="/assets/img/photos/rachelsroom/black-and-white-black-and-white-boy-141651.jpg">
                <img class="img_25" src="/assets/img/photos/rachelsroom/adorable-animal-black-and-white-825947.jpg">
                <img class="img_50_25" src="/assets/img/photos/rachelsroom/beard-black-and-white-elderly-man-2220856.jpg"> 
                <img class="img_100" src="/assets/img/photos/rachelsroom/attractive-beautiful-beauty-1027406.jpg">
                <!-- <a href=""><p>PLAY</p></a> -->
                <div class="img_33"></div>
                <img class="img_33" src="/assets/img/photos/rachelsroom/adorable-animal-black-and-white-825947.jpg">
                <img class="img_33" src="/assets/img/photos/rachelsroom/beard-black-and-white-elderly-man-2220856.jpg">             
            </div>
        </div>
    </div>
</div>