<div class="navbar-desktop" id="navbar-desktop">
    <mat-toolbar>
    <mat-toolbar-row>
        <button>Home</button>
        <button>About</button>
        <button>Blog</button>
        <button>Gallery</button>
        <button>Contact</button>
        <span class="spacer"></span>
        <i class="fas fa-search"></i>
        <i class="fas fa-bars"></i>
    </mat-toolbar-row>
    </mat-toolbar>
</div>

<div id="jumbotron_home">
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h1>Nutrition</h1>
            <div class="webSlides">
                <h2>01</h2>
                <h2>02</h2>
                <h2>03</h2>
                <h2>04</h2>
                <h2 style="color:#FFFFFF;">05</h2>                
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
            <h6>Lorem ipsum dolor sit amet, an vel debet recusabo consulatu, ea recusabo splendide quo. Per ne malorum invenire, cu platonem eloquentiam interpretaris his. Affert congue discere ut has, ne alterum oporteat consetetur est. Suas causae iracundia ius cu, ei eum mentitum referrentur comprehensam.</h6>
            <p>Discover More</p>
            <a href="#"><i class="fas fa-play"></i></a>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">         
        </div>
    </div>
</div>