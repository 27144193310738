<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
            <mat-toolbar-row>
                <button>Home</button>
                <button>About</button>
                <button>Blog</button>
                <button>Gallery</button>
                <button>Contact</button>
                <span class="spacer"></span>
                <i class="fas fa-search"></i>
                <i class="fas fa-bars"></i>
            </mat-toolbar-row>
        </mat-toolbar>        
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <img src="/assets/img/photos/olivia/girl-with-purse-orange-background.png">
            </div>
            <div class="navbar-mobile-center">
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <img src="/assets/img/photos/olivia/girl-with-purse-orange-background.png">
            </div>
            <div class="side-nav-center">
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Blog</button><hr>
                <button (click)="toggleSideNav()">Gallery</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img src="/assets/img/photos/olivia/girl-with-purse-orange-background.png">
                <h1>Olivia</h1>
                <h2>The world through my eyes.</h2>
                <a href="#"><i class="fas fa-long-arrow-alt-right"></i></a>
                <p>View more</p>
            </div>
        </div>
    </div>
</div>