<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
        <mat-toolbar-row>
            <button style="color: #CFE4BC;">Home</button>
            <button>About</button>
            <button>Catalog</button>
            <button>Contact</button>
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Services</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <h1>All of our artisan doughnuts are handcrafted with the finest organic cool milled wheat flour and ingredients from locally sourced vendors.</h1>
                <h2>Doughnuts</h2>
                <a>Get Some!</a>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                <div id="donut_one">
                    <img src="/assets/img/photos/doughnuts/donut-green-background.jpg">
                </div>
                <div id="donut_one">
                    <img src="/assets/img/photos/doughnuts/accessory-donut-doughnut.jpg">
                </div>
            </div>
            <div class="col-lg-3 col-md-12 col-sm-12 col-12">
                <div id="donut_two">
                    <img src="/assets/img/photos/doughnuts/donut-white-background.jpg">
                    <a>Apple Cinnamon</a>
                    <a>Blueberry</a>
                    <a>Boston Creme</a>
                    <a>Cider</a>
                    <a>Custard</a>
                    <a>Double Chocolate</a>
                    <a>Glazed</a>
                    <a>Maple</a>
                    <a>Red Velvet</a>
                    <a>Sour Cream</a>
                    <a>Strawberry</a>
                    <a>& More</a>
                </div>
            </div>
        </div>
    </div>
</div>