import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fashion',
  templateUrl: './fashion.component.html',
  styleUrls: ['./fashion.component.scss']
})
export class FashionComponent implements OnInit {

  appTitle = 'Fashion';

  toggleSideNav() {
    const x = document.getElementById('sideNav');
    const y = document.getElementsByTagName('BODY')[0];

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
