<div class="navbar-desktop" id="navbar-desktop">
    <div class="container-fluid">
        <mat-toolbar>
        <mat-toolbar-row>
            <button style="color: #C0392B;">Home</button>
            <button>Car Fleet</button>
            <button>Experience</button>
            <button>About</button>
            <button>Contact</button>
            <span class="spacer"></span>
            <i class="fas fa-search"></i>
            <i class="fas fa-bars"></i>
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container-fluid">
        <div class="row">
            <div class="navbar-mobile-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container-fluid">
        <div class="row">
            <div class="side-nav-left">
                <!-- <img src="/../assets/img/photos/adventurebiker/logo.png"> -->
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <hr><button (click)="toggleSideNav()">Home</button><hr>
                <button (click)="toggleSideNav()">About</button><hr>
                <button (click)="toggleSideNav()">Services</button><hr>
                <button (click)="toggleSideNav()">Contact</button><hr>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Exotic</h1>               
                <img src="/assets/img/photos/exoctic/luxury-exotic-car-bmw.png">
                <h2>
                    01<br>
                    02<br>
                    03<br>
                    04<br>
                    05<br>
                </h2> 
            </div>
        </div>
        <div class="row">   
            <div class="col-lg-4 col-md-6 col-sm-6 col-8">
                <p>Lorem ipsum dolor sit amet, an vel debet recusabo consulatu, ea recusabo splendide quo. Per ne malorum invenire, cu platonem eloquentiam interpretaris his. Affert congue discere ut has, ne alterum oporteat consetetur est. Suas causae iracundia ius cu, ei eum mentitum referrentur comprehensam.</p>
            </div>
            <div class="col-lg-8 col-md-6 col-sm-6 col-4">
                <a>Discover More<i class="fas fa-play"></i></a>
            </div>        
        </div>
    </div>
</div>