import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exotic',
  templateUrl: './exotic.component.html',
  styleUrls: ['./exotic.component.scss']
})
export class ExoticComponent implements OnInit {

  appTitle = 'Exotic';

  toggleSideNav() {
    const x = document.getElementById('sideNav');
    const y = document.getElementsByTagName('BODY')[0];

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
