import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homedecor',
  templateUrl: './homedecor.component.html',
  styleUrls: ['./homedecor.component.scss']
})
export class HomedecorComponent implements OnInit {

  appTitle = 'Home Decor';

  toggleSideNav() {
    const x = document.getElementById('sideNav');
    const y = document.getElementsByTagName('BODY')[0];

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
