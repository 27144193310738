import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload',
  enableTracing: false,
  scrollOffset: [0, 80], // [x , y],
  scrollPositionRestoration: 'enabled'
};

import { HomeComponent } from './home/home.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { AdventurebikerComponent } from './adventurebiker/adventurebiker.component';
import { CrabbcutzComponent } from './crabbcutz/crabbcutz.component';
import { DivineComponent } from './divine/divine.component';
import { DoughnutsComponent } from './doughnuts/doughnuts.component';
import { ExoticComponent } from './exotic/exotic.component';
import { FamilytidesComponent } from './familytides/familytides.component';
import { FashionComponent } from './fashion/fashion.component';
import { GenerationweirdosComponent } from './generationweirdos/generationweirdos.component';
import { HomedecorComponent } from './homedecor/homedecor.component';
import { HugosComponent } from './hugos/hugos.component';
import { JennynailsComponent } from './jennynails/jennynails.component';
import { LunamarsComponent } from './lunamars/lunamars.component';
import { MiahComponent } from './miah/miah.component';
import { NailsbyusComponent } from './nailsbyus/nailsbyus.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { OliviaComponent } from './olivia/olivia.component';
import { OnephotographyComponent } from './onephotography/onephotography.component';
import { RacheljComponent } from './rachelj/rachelj.component';
import { SweettoothComponent } from './sweettooth/sweettooth.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full'},
  { path: '', component: HomeComponent },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'adventurebiker', component: AdventurebikerComponent },
  { path: 'crabbcutz', component: CrabbcutzComponent },
  { path: 'divine', component: DivineComponent },
  { path: 'doughnuts', component: DoughnutsComponent },
  { path: 'exotic', component: ExoticComponent },
  { path: 'familytides', component: FamilytidesComponent },
  { path: 'fashion', component: FashionComponent },
  { path: 'generationweirdos', component: GenerationweirdosComponent },
  { path: 'homedecor', component: HomedecorComponent },
  { path: 'hugos', component: HugosComponent },
  { path: 'jennynails', component: JennynailsComponent },
  { path: 'lunamars', component: LunamarsComponent },
  { path: 'miah', component: MiahComponent },
  { path: 'nailsbyus', component: NailsbyusComponent },
  { path: 'nutrition', component: NutritionComponent },
  { path: 'olivia', component: OliviaComponent },
  { path: 'onephotography', component: OnephotographyComponent },
  { path: 'rachelj', component: RacheljComponent },
  { path: 'sweettooth', component: SweettoothComponent },
  { path: '**', component: FourZeroFourComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
