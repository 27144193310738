export const templates = [
    {
      img: 'adventurebiker',
      name: 'Adventure Biker',
      category: 'Sport',
      view: 'adventurebiker'
    },
    {
      img: 'crabbcutz',
      name: 'Crabb Cutz',
      category: 'Business',
      view: 'crabbcutz'
    },
    {
      img: 'doughnuts',
      name: 'Doughnuts',
      category: 'Bakery',
      view: 'doughnuts'
    },
    {
      img: 'exotic',
      name: 'Exotic',
      category: 'Business',
      view: 'exotic'
    },
    {
      img: 'fashion',
      name: 'Fashion',
      category: 'Online Store',
      view: 'fashion'
    },
    {
      img: 'generationweirdos',
      name: 'Generation Weirdos',
      category: 'Music',
      view: 'generationweirdos'
    },
    {
      img: 'homedecor',
      name: 'Home Decor',
      category: 'Business',
      view: 'homedecor'
    },
    {
      img: 'hugos',
      name: 'Hugos',
      category: 'Restaurant',
      view: 'hugos'
    },
    {
      img: 'jennynails',
      name: 'Jenny Nails',
      category: 'Business',
      view: 'jennynails'
    },
    {
      img: 'lunamars',
      name: 'Luna Mars',
      category: 'Business',
      view: 'lunamars'
    },
    {
      img: 'miah',
      name: 'MIAH Photography',
      category: 'Photography',
      view: 'miah'
    },
    {
      img: 'nailsbyus',
      name: 'Nails By Us',
      category: 'Business',
      view: 'nailsbyus'
    },
    {
      img: 'nutrition',
      name: 'Nutrition',
      category: 'Fitness',
      view: 'nutrition'
    },
    {
      img: 'oliviastravelblog',
      name: 'Olivia\'s Travel Blog',
      category: 'Travel',
      view: 'olivia'
    },
    {
      img: 'onephotography',
      name: 'One Photography',
      category: 'Photography',
      view: 'onephotography'
    },
    {
      img: 'rachelj',
      name: 'Rachel  J',
      category: 'Photography',
      view: 'rachelj'
    },
    {
      img: 'sweettooth',
      name: 'Sweet Tooth',
      category: 'Bakery',
      view: 'sweettooth'
    },
]