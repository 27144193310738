<div class="navbar-desktop">
    <div class="container">
        <mat-toolbar>
        <mat-toolbar-row>
            <button style="color: #F1C40F;">Home</button>
            <button>About</button>
            <button>Services</button>
            <button>Gallery</button>
            <button>Contact</button>
            <span class="spacer"></span>
            <a><i class="fab fa-facebook-f"></i></a>
            <a><i class="fab fa-instagram"></i></a>
            <a><i class="fab fa-twitter"></i></a>
            <a><i class="fab fa-pinterest"></i></a>
        </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>

<div class="navbar-mobile">
    <div class="container">
        <div class="row">
            <div class="navbar-mobile-left">
                <img src="/assets/img/photos/sweettooth/logo.jpg">
                <p>{{ appTitle }}</p>
            </div>
            <div class="navbar-mobile-center">
            </div>
            <div class="navbar-mobile-right">
                <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
            </div>
        </div>
    </div>
</div>

<div id="sideNav">
    <div class="container">
        <div class="row">
            <div class="side-nav-left">
                <img src="/assets/img/photos/sweettooth/logo.jpg">
                <p>{{ appTitle }}</p>
            </div>
            <div class="side-nav-center">
            </div>
            <div class="side-nav-right">
                <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
            </div>
        </div>
        <div class="row">
            <div class="side-nav-content">
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()">Home</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()">About</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()">Services</button>
                <mat-divider></mat-divider>
                <button (click)="toggleSideNav()">Contact</button>
                <mat-divider></mat-divider>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_home">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="jumbotron_home_banner">
                    <img src="/assets/img/photos/sweettooth/logo.jpg">
                    <h1>Sweet Tooth</h1>
                    <p>Cravings from around the world. Delicious pastries and more!</p>
                </div>
                <a href="" style="margin: 0px 50px 0px 0px;"><i class="fas fa-chevron-left"></i></a>
                <a href=""><i class="fas fa-chevron-right"></i></a>
            </div>
        </div>
    </div>
</div>