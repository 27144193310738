<div class="jumbotron_home">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <p>Website Template Coming Soon!</p>
                <a href="https://www.thegreenedgellc.com">View More Templates</a>
            </div>
        </div>
    </div>
</div>
