import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { CustomMaterialModule } from './material';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';

import { AdventurebikerComponent } from './adventurebiker/adventurebiker.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { CrabbcutzComponent } from './crabbcutz/crabbcutz.component';
import { DivineComponent } from './divine/divine.component';
import { DoughnutsComponent } from './doughnuts/doughnuts.component';
import { ExoticComponent } from './exotic/exotic.component';
import { FamilytidesComponent } from './familytides/familytides.component';
import { FashionComponent } from './fashion/fashion.component';
import { GenerationweirdosComponent } from './generationweirdos/generationweirdos.component';
import { HomedecorComponent } from './homedecor/homedecor.component';
import { HugosComponent } from './hugos/hugos.component';
import { JennynailsComponent } from './jennynails/jennynails.component';
import { LunamarsComponent } from './lunamars/lunamars.component';
import { MiahComponent } from './miah/miah.component';
import { NailsbyusComponent } from './nailsbyus/nailsbyus.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { OliviaComponent } from './olivia/olivia.component';
import { OnephotographyComponent } from './onephotography/onephotography.component';
import { RacheljComponent } from './rachelj/rachelj.component';
import { SweettoothComponent } from './sweettooth/sweettooth.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    FourZeroFourComponent,
    AdventurebikerComponent,
    ComingSoonComponent,
    CrabbcutzComponent,
    DivineComponent,
    DoughnutsComponent,
    ExoticComponent,
    FamilytidesComponent,
    FashionComponent,
    GenerationweirdosComponent,
    HomedecorComponent,
    HugosComponent,
    JennynailsComponent,
    LunamarsComponent,
    MiahComponent,
    NailsbyusComponent,
    NutritionComponent,
    OliviaComponent,
    OnephotographyComponent,
    RacheljComponent,
    SweettoothComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CustomMaterialModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
