import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doughnuts',
  templateUrl: './doughnuts.component.html',
  styleUrls: ['./doughnuts.component.scss']
})
export class DoughnutsComponent implements OnInit {

  appTitle = 'Doughnuts';

  toggleSideNav() {
    const x = document.getElementById('sideNav');
    const y = document.getElementsByTagName('BODY')[0];

    if (x.style.display === 'block') {
      x.style.display = 'none';
    } else {
      x.style.display = 'block';
    }
  }

  constructor() { }

  ngOnInit() {
  }

}
