import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  appTitle = 'The Green Edge LLC';

  year: number = new Date().getFullYear();

  toTopButton() {
    window.scroll(0, 0);
  }

  constructor() {
  }

  ngOnInit() {
    // TO TOP BUTTON
    window.onscroll = function() {
        scrollFunction();
    };

    function scrollFunction() {
        if (document.body.scrollTop > 850 || document.documentElement.scrollTop > 850) {
            document.getElementById('ToTopButton').style.display = 'block';
        } else {
            document.getElementById('ToTopButton').style.display = 'none';
        }
    }
  }
}